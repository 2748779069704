var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pt-4"},[_c('v-container',{staticClass:"px-15",attrs:{"fluid":""}},[(!_vm.isLoading)?_c('v-row',{staticClass:"ma-0 mt-5",attrs:{"justify":"start","align":"start"}},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"headline-medium text-primary"},[_vm._v("Historial de egresos")])]),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"body-large text-secondary"},[_vm._v(" Aqui encontraras el historial de todos tus pagos realizados. ")])])],1):_c('v-skeleton-loader',{staticClass:"mx-3 my-3",attrs:{"height":"200px","width":"400px","type":"image"}}),(!_vm.isLoading)?_c('v-card',{staticClass:"rounded-xl elevation-5 mt-5"},[_c('v-toolbar',{attrs:{"dense":"","dark":"","color":"#1d2b3c","elevation":"2"}},[_c('v-toolbar-title',[_vm._v("Pagos")]),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","small":""},on:{"click":function($event){return _vm.dataXLSX()}}},[_c('h4',{staticStyle:{"font-family":"'montserrat-bold'"}},[_vm._v("Descargar")]),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-microsoft-excel")])],1)],1),_c('v-spacer'),_c('v-card-title',{staticStyle:{"justify-content":"flex-end"}},[_c('div',{staticStyle:{"background":"#ededed","box-shadow":"0px 4px 10px rgba(0, 0, 0, 0.15)","border-radius":"10px","padding":"15px","border":"1px solid"}},[_vm._v(" Total currency gain: "),_c('span',{staticClass:"ml-2",staticStyle:{"font-weight":"bold"},style:({ color: _vm.totalProfit < 0 ? 'red' : 'green'})},[_vm._v(" "+_vm._s(" $" + _vm.formatMoney(_vm.totalProfit) + " USD")+" ")])])]),_c('v-card-text',[_c('v-spacer'),_c('v-data-table',{attrs:{"headers":_vm.headersForex,"items":_vm.items},scopedSlots:_vm._u([{key:"item.iTrackingId",fn:function(ref){
var item = ref.item;
return [_c('h4',{staticStyle:{"text-wrap":"nowrap","font-family":"'Montserrat', sans-serif"}},[_vm._v(" "+_vm._s(item.iTrackingId)+" ")])]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [(
                item.sTransactionType == 'b80e8dc6-4138-449e-8903-968efb8437b3'
              )?_c('v-chip',{staticClass:"ma-2",attrs:{"color":"red","outlined":""}},[_vm._v(" "+_vm._s("Forward")+" ")]):(
                item.sTransactionType == 'e7b157eb-8dcb-4655-8d0e-4eb4e7e9270a'
              )?_c('v-chip',{staticClass:"ma-2",attrs:{"color":"blue","outlined":""}},[_vm._v(" "+_vm._s("Forex")+" ")]):(
                item.sTransactionType == 'ea9d350f-f00b-4763-90db-72e7af54dc73'
              )?_c('v-chip',{staticClass:"ma-2",attrs:{"color":"orange","outlined":""}},[_vm._v(" "+_vm._s("Pay Now")+" ")]):_c('div',[_vm._v(" "+_vm._s("N/A")+" ")])]}},{key:"item.dAmount",fn:function(ref){
              var item = ref.item;
return [_c('h4',{staticStyle:{"text-wrap":"nowrap","font-family":"'Montserrat', sans-serif"}},[_vm._v(" "+_vm._s(item.dAmount ? "$ " + _vm.formatMoney(item.dAmount) + " USD" : "N/A")+" ")])]}},{key:"item.invoice",fn:function(ref){
              var item = ref.item;
return [_c('h4',{staticStyle:{"text-wrap":"nowrap","font-family":"'Montserrat', sans-serif"}},[_vm._v(" "+_vm._s(item.invoice ? item.invoice : "N/A"))])]}},{key:"item.dDesAmount",fn:function(ref){
              var item = ref.item;
return [_c('h4',{staticStyle:{"text-wrap":"nowrap","font-family":"'Montserrat', sans-serif"}},[_vm._v(" "+_vm._s(item.dDesAmount ? "$ " + _vm.formatMoney(item.dDesAmount) + " MXN" : "N/A")+" ")])]}},{key:"item.dExchangeRate",fn:function(ref){
              var item = ref.item;
return [_c('h4',{staticStyle:{"text-wrap":"nowrap","font-family":"'Montserrat', sans-serif"}},[_vm._v(" "+_vm._s(item.dExchangeRate ? "$ " + _vm.formatMoney(item.dExchangeRate) + " MXN" : "N/A")+" ")])]}},{key:"item.dProfitPercentage",fn:function(ref){
              var item = ref.item;
return [(item.invoice_payments_relationship.length > 0)?_c('h4',{staticStyle:{"text-wrap":"nowrap","font-family":"'Montserrat', sans-serif"},style:({
                color: item.dCurrencyProfitPercentage < 0 ? 'red' : 'green'
              })},[_vm._v(" "+_vm._s(item.invoice_payments_relationship.length > 0 ? item.invoice_payments_relationship[0].invoice .dProfitPercentage + "%" : "")+" ")]):_c('h4',[_vm._v(_vm._s("N/A"))])]}},{key:"item.dCurrencyGain",fn:function(ref){
              var item = ref.item;
return [(item.invoice_payments_relationship.length > 0)?_c('h4',{staticStyle:{"text-wrap":"nowrap","font-family":"'Montserrat', sans-serif"},style:({
                color: item.dCurrencyProfitPercentage < 0 ? 'red' : 'green'
              })},[_vm._v(" "+_vm._s(item.invoice_payments_relationship.length > 0 ? "$ " + _vm.formatMoney( item.invoice_payments_relationship[0].invoice .dCurrencyGain ) + " USD" : "")+" ")]):_c('h4',[_vm._v(_vm._s("N/A"))])]}},{key:"item.created_at",fn:function(ref){
              var item = ref.item;
return [_c('h4',{staticStyle:{"text-wrap":"nowrap","font-family":"'Montserrat', sans-serif"}},[_vm._v(" "+_vm._s(_vm.formatDate(item.created_at))+" ")])]}},{key:"item.percentProfit",fn:function(ref){
              var item = ref.item;
return [(item.percentProfit)?_c('h4',{staticStyle:{"text-wrap":"nowrap","font-family":"'Montserrat', sans-serif"},style:({ color: item.percentProfit < 0 ? 'red' : 'green' })},[_vm._v(" "+_vm._s(item.percentProfit ? _vm.formatMoney(item.percentProfit) + "%" : "N/A")+" ")]):_c('h4',[_vm._v(_vm._s("N/A"))])]}},{key:"item.dollarProfit",fn:function(ref){
              var item = ref.item;
return [(item.dollarProfit)?_c('h4',{staticStyle:{"text-wrap":"nowrap","font-family":"'Montserrat', sans-serif"},style:({ color: item.dollarProfit < 0 ? 'red' : 'green' })},[_vm._v(" "+_vm._s(item.dollarProfit ? "$ " + _vm.formatMoney(item.dollarProfit) + " USD" : "")+" ")]):_c('h4',[_vm._v(_vm._s("N/A"))])]}},{key:"item.sName",fn:function(ref){
              var item = ref.item;
return [_c('h4',{staticStyle:{"text-wrap":"nowrap","font-family":"'Montserrat', sans-serif"}},[_vm._v(" "+_vm._s(item.payment_recipient.sName)+" ")])]}},{key:"item.sEmail",fn:function(ref){
              var item = ref.item;
return [_c('h4',{staticStyle:{"text-wrap":"nowrap","font-family":"'Montserrat', sans-serif"}},[_vm._v(" "+_vm._s(item.payment_recipient.sEmail)+" ")])]}},{key:"item.sBankName",fn:function(ref){
              var item = ref.item;
return [_c('h4',{staticStyle:{"text-wrap":"nowrap","font-family":"'Montserrat', sans-serif"}},[_vm._v(" "+_vm._s(item.payment_recipient.sBankName)+" ")])]}},{key:"item.sAccountNumber",fn:function(ref){
              var item = ref.item;
return [_c('h4',{staticStyle:{"text-wrap":"nowrap","font-family":"'Montserrat', sans-serif"}},[_vm._v(" "+_vm._s(item.payment_recipient.sAccountNumber)+" ")])]}},{key:"item.sTaxId",fn:function(ref){
              var item = ref.item;
return [_c('h4',{staticStyle:{"text-wrap":"nowrap","font-family":"'Montserrat', sans-serif"}},[_vm._v(" "+_vm._s(item.payment_recipient.sTaxId)+" ")])]}},{key:"item.actions",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticStyle:{"display":"flex","align-items":"center","justify-content":"center"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"black"},on:{"click":function($event){return _vm.openReceipt(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v("Ver detalle")])])],1)]}}],null,false,2148158291)})],1)],1):_c('v-skeleton-loader',{staticClass:"mx-3 my-3",attrs:{"height":"500px","type":"image"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }