var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pt-4"},[_c('v-container',{staticClass:"px-15",attrs:{"fluid":""}},[_c('v-row',{staticClass:"ma-0",attrs:{"justify":"start","align":"start"}},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"headline-medium text-primary"},[_vm._v("Estado de cuenta")])]),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"body-large text-secondary"},[_vm._v(" Aqui podras encontrar todo el historial de las transacciones que haz realizado en tu cuenta. ")])])],1)],1),_c('v-card',{staticClass:"rounded-xl elevation-5 ma-15 mt-5"},[_c('v-toolbar',{attrs:{"dense":"","dark":"","color":"#1d2b3c","elevation":"2"}},[_c('v-toolbar-title',[_vm._v("Estado de cuenta")]),_c('v-spacer'),_c('v-btn',{attrs:{"elevation":"0","text":"","small":""}})],1),_c('v-card-title'),_c('v-card-text',[_c('v-spacer'),_c('v-data-table',{attrs:{"headers":_vm.headersForex,"items":_vm.itemsForex,"disable-sort":true,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.type",fn:function(ref){
var item = ref.item;
return [(item.type == 'Forex')?_c('v-chip',{staticClass:"ma-2",attrs:{"color":"orange","outlined":""}},[_vm._v(" "+_vm._s(item.type)+" ")]):_c('v-chip',{staticClass:"ma-2",attrs:{"color":"blue","outlined":""}},[_vm._v(" "+_vm._s(item.type)+" ")])]}},{key:"item.dTotalAmount",fn:function(ref){
var item = ref.item;
return [_c('h4',{staticStyle:{"text-wrap":"nowrap","font-family":"'Montserrat', sans-serif"}},[_vm._v(" $ "+_vm._s(_vm.formatMoney(item.dTotalAmount))+" MXN ")])]}},{key:"item.dRateProtection",fn:function(ref){
var item = ref.item;
return [_c('h4',{staticStyle:{"text-wrap":"nowrap","font-family":"'Montserrat', sans-serif"}},[_vm._v(" $ "+_vm._s(_vm.formatMoney(item.dRateProtection))+" MXN ")])]}},{key:"item.dProfitPercentage",fn:function(ref){
var item = ref.item;
return [_c('h4',{staticStyle:{"text-wrap":"nowrap","font-family":"'Montserrat', sans-serif"},style:({ color: item.dCurrencyProfitPercentage < 0 ? 'red' : 'green'})},[_vm._v(" "+_vm._s(item.dProfitPercentage ? (item.dProfitPercentage + "%") : "")+" ")])]}},{key:"item.dCurrencyGain",fn:function(ref){
var item = ref.item;
return [_c('h4',{staticStyle:{"text-wrap":"nowrap","font-family":"'Montserrat', sans-serif"},style:({ color: item.dCurrencyProfitPercentage < 0 ? 'red' : 'green'})},[_vm._v(" "+_vm._s(item.dCurrencyGain ? ('$ ' + _vm.formatMoney(item.dCurrencyGain) + ' USD') : "")+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"display":"flex","align-items":"center","justify-content":"center"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"black"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit item")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"red"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete item")])])],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }