<template>
  <div>
    <navbar-component />
    <v-app style="padding-top: 64px" id="inspire">
      <v-navigation-drawer v-model="drawer" width="280" app absolute>
        <v-list-item title="My Application" subtitle="Vuetify">
          <v-img :src="require('../../assets/logos/logo-bg-white.svg')"></v-img>
        </v-list-item>
        <v-divider></v-divider>
        <template v-for="(section, index) in sections">
          <v-list-group
            v-if="!loading"
            :prepend-icon="section.icon"
            :value="false"
            v-model="section.active"
            :class="{ 'active-item': selectedMenuItem === section.value }"
            :color="'#f0be44'"
          >
            <template v-slot:activator>
              <v-list-item-title>{{ section.title }}</v-list-item-title>
            </template>
            <v-list-item
              v-for="(item, itemIndex) in section.items"
              :key="itemIndex"
              link
              :to="item.path"
              color="#f0be44"
              :class="{ 'active-item': selectedMenuItem === item.value }"
              :disabled="item.disabled"
              style="padding-left: 35px"
            >
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list-group>
          <v-skeleton-loader
            v-else
            class="mx-auto"
            type="image"
          ></v-skeleton-loader>
        </template>
      </v-navigation-drawer>
      <!-- <v-app-bar dense height="83" app style="display: flex; justify-content: space-between; border-bottom: 1px solid #c7c7c7; align-items: center; padding: 1.25rem;" flat color="#ffffff">
      </v-app-bar> -->
      <v-app-bar color="#ffffff" absolute app height="83">
        <v-toolbar-title
          style="
            font-size: 0.875rem;
            line-height: 1.25rem;
            font-weight: 600;
            display: flex;
            align-items: center;
          "
          class="pa-0"
          >{{ formattedDate }}</v-toolbar-title
        >
        <v-spacer></v-spacer>
        <!-- <div>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <div class="ml-3" style="display: flex; cursor: pointer; border: 1px #585858 solid; align-items: center; border-radius: 15px; padding: 10px" v-bind="attrs" v-on="on">
                <img
                  v-if="selectLanguage == 'sp'"
                  style="cursor: pointer;"
                  src="../../assets/images/Flags_mex.png"
                  alt="proplat-logo-white"
                  class="mr-3"
                  height="30"
                  width="50"
                />
                <img
                  v-else
                  style="cursor: pointer;"
                  src="../../assets/images/Flags_usa.png"
                  alt="proplat-logo-white"
                  class="mr-3"
                  height="30"
                  width="50"
                />
                <h5>{{ selectLanguage == "sp" ? "Español" : "English" }}</h5>
                
                <v-icon>mdi-menu-down</v-icon>
              </div>
            </template>
            <language-component style="background-color: black; padding: 15px;"></language-component>
          </v-menu>
        </div> -->
      </v-app-bar>
      <v-main>
        <router-view></router-view>
      </v-main>
    </v-app>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "DashboardView",
  data() {
    return {
      texts: "",
      loading: true,
      currentDate: moment(),
      drawer: null,
      balance: "0",
      items: [],
      tab: 0,
      editableData: {},
      selectedMenuItem: "",
      contactData: {
        sName: "",
        sEmail: "",
        sBank_name: "",
        sAccount_number: "",
        sEntity_business: "COMPANY"
      },
      showCreateProvider: false,
      isResponsive: false,
      screenWidth: 0,
      timerCount: 30,
      userName: "",
      sections: [
        {
          icon: "mdi mdi-view-dashboard",
          value: "operation",
          title: "Operaciones",
          items: [
            {
              icon: "mdi mdi-view-dashboard",
              value: "exchange",
              title: "Dashboard",
              path: "/exchange",
              disabled: false
            },
            {
              icon: "mdi-file-document",
              value: "history",
              title: "Seguimiento de facturas",
              path: "/invoices",
              disabled: false
            },
            {
              icon: "mdi-forward",
              value: "activeForwards",
              title: "Forwards activos",
              path: "/forwards",
              disabled: false
            },
            {
              icon: "mdi-cash",
              value: "activeForwards",
              title: "Saldo disponible MXN",
              path: "/balance",
              disabled: false
            }
          ]
        },
        {
          icon: "mdi-history",
          value: "history",
          title: "Historial",
          items: [
            {
              icon: "mdi-file-document",
              value: "history",
              title: "Historial de ingresos",
              path: "/test",
              disabled: true
            },
            {
              icon: "mdi-file-document",
              value: "exchangeRate",
              title: "Historial de conversiones",
              path: "/conversions",
              disabled: false
            },
            {
              icon: "mdi-file-document",
              value: "history",
              title: "Historial de egresos",
              path: "/payments",
              disabled: false
            }
          ]
        },
        {
          icon: "mdi-wallet",
          value: "wallets",
          title: "Wallets",
          items: [
            {
              icon: "mdi-account-group",
              value: "suppliers",
              title: "Proveedores",
              path: "/suppliers",
              disabled: false
            },
            {
              icon: "mdi-note-text-outline",
              value: "suppliers",
              title: "Estado de cuenta",
              path: "/statement",
              disabled: true
            }
          ]
        },
        // {
        //   //Settings
        //   icon: "mdi-cog",
        //   value: "settings",
        //   title: "Ajustes",
        //   items: [
        //     {
        //       icon: "mdi-shield-account",
        //       value: "security",
        //       title: "Seguridad",
        //       path: "/security",
        //       disabled: false
        //     }
        //   ]
        // }
      ]
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  methods: {
    handleResize: function () {
      if (window.innerWidth >= 960) {
        this.screenWidth = 30;
      } else {
        this.screenWidth = 100;
      }

      this.isResponsive = window.innerWidth < 960;
    },
    createSubAccount(data) {
      const payload = {
        sBank_name: data.sBankName,
        sAccount_number: data.sAccountNumber,
        sRouting_number: data.sRoutingNumber,
        clabe: data.clabe,
        sBank_mxn_account: data.sBankMxnAccount
      };

      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`
        }
      };

      DB.post(
        `${URI}/api/v1/${this.selectLanguage}/sub-account/`,
        payload,
        config
      )
        .then((response) => {
          this.mixSuccess(response.data.message);
          this.getExchangeRate();
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    getDashboard() {
      this.loading = true;
      DB.get(`${URI}/api/v1/${this.selectLanguage}/dashboard`, {
        headers: {
          Authorization: this.$store.state.sToken
            ? `Bearer ${this.$store.state.sToken}`
            : ""
        }
      }).then((response) => {
        this.loading = false;
      }).catch((error) => {
        if (error.response.data.code) {
          this.$router.push("/welcome").catch(() => {});
        }
        // this.isLoadingSuppliers = false;
        this.mixError(error.response.data.message, error.response.status);
      });
    },
    updateTime() {
      this.currentDate = moment(); // Actualiza la fecha y hora
    }
  },
  beforeMount() {
    this.texts = FILE.takeRate[this.selectLanguage];
    this.interval = setInterval(this.updateTime, 1000); // 1000 ms = 1 segundo
    this.getDashboard();
  },
  beforeDestroy() {
    // Limpia el intervalo cuando el componente se destruye
    clearInterval(this.intervalId);
  },
  computed: {
    selectLanguage: function () {
      return this.$store.state.language;
    },
    formattedDate() {
      return this.currentDate.format("MMMM DD, YYYY, HH:mm:ss [GMT]Z");
    },
    formattedTime() {
      const minutes = Math.floor(this.timerCount / 60);
      const seconds = this.timerCount % 60;
      return `${minutes.toString().padStart(2, "0")}:${seconds
        .toString()
        .padStart(2, "0")}`;
    }
  },
  watch: {
    selectLanguage: function () {
      if (this.selectLanguage) {
        this.texts = FILE.takeRate[this.selectLanguage];
      }
    },
    "editableData.sProvider": function (newValue) {
      this.showCreateProvider = newValue === 0;
    }
  }
};
</script>

<style>
.active {
  background-color: #f0f0f0;
}
.active-section .v-list-item__icon {
  color: yellow !important;
  background-color: yellow !important;
}

.active-section .v-list-item__title {
  background-color: yellow !important;
}

.active-item {
  background-color: #f5f7f9 !important;
}

.v-list-item__content {
  font-family: 'montserrat-semibold';
}
</style>
