var texts = {
    en: {
        dataTable: {
            purchaseDate: 'Purchase date',
            provider: 'Supplier',
            invoice: 'Invoice',
            totalAmount: 'Total amount',
            creditPeriod: 'Credit period',
            dueDate: 'Due date',
            exchangeRateProtection: 'Exchange rate protection',
            exchangeRateToday: 'Exchange rate today',
            currencyProfit: 'Currency profit (%)',
            totalProtectedUsd: 'Total protected (USD)',
            totalUsdToday: 'Total USD today',
            currencyGain: 'Currency gain',
            currencyProfitUsd: 'Currency profit (USD)',
            actions: 'Actions',
            quote: 'Quote',
            status: 'Status',
            add: 'Add',
            addFounds: 'Add founds',
            edit: 'Edit',
            receipt: 'Receipt',
        },
        modalForm: {
            textTitle: 'Modify',
            purchaseDate: 'Purchase date',
            provider: 'Supplier',
            invoice: 'Invoice',
            totalAmount: 'Total amount',
            creditPeriod: 'Credit period',
            dueDate: 'Due date',
            exchangeRateProtection: 'Exchange rate protection',
            currencyProfit: 'Currency profit (%)',
            currencyProfitUsd: 'Currency profit (USD)',
            totalProtectedUsd: 'Total protected (USD)',
            currencyGain: 'Currency gain',
            exchangeRateToday: 'Exchange rate today',
            totalUsdToday: 'Total USD today',
            adjust: 'Adjust',

            cancel: 'Cancel',
        },
        textTitle: 'Modify',
        textTitleSepare: 'Quote',
        textTitleCreate: 'Create',
        register: {
            register: 'Register',
            sBankName: 'Bank name',
            sAccountNumber: 'Account number',
            sRoutingNumber: 'Routing number',
            clabe: 'Clabe',
            sBankNameMxn: 'Bank name (MXN)',
        },
        registerProvider: {
            email: 'Email',
            name: 'Name',
            country: 'Country',
            bank_name: 'Bank name',
            clabe: 'Clabe number',
        },
        status: {
            TO_PAY: 'To pay',
            COMPLETED: 'Paid',
            SEPARATED: 'Separated',
            WAITING_FOR_DEPOSIT: 'Waiting for deposit',
            PENDING_TO_PROCESS_SCHEDULED_PAYMENT: 'Pending to process scheduled payment',
        },
        payments: {
            payNow: 'Pay Now',
            payNowDescription: 'Use your account balance',
            paySeparate: 'Convert payment to MXN',
            paySeparateDescription: "Convert your USD to MXN, then choose when to pay your supplier",
            payDeferred: 'Deferred Payment',
            payDeferredDescription: 'Lock an exchange rate, then pay via bank transfer',
            payForward: 'Forward Payment',
            payForwardDescription: 'Guard against exchange rate fluctuations',
        }
    },
    sp: {
        dataTable: {
            purchaseDate: 'Fecha de compra',
            provider: 'Proveedor',
            invoice: 'Factura',
            totalAmount: 'Monto total',
            creditPeriod: 'Plazo de crédito',
            dueDate: 'Fecha de vencimiento',
            exchangeRateProtection: 'Protección de tipo de cambio',
            exchangeRateToday: 'Tipo de cambio hoy',
            currencyProfit: 'Ganancia de moneda (%)',
            currencyProfitUsd: 'Ganancia de moneda (USD)',
            totalProtectedUsd: 'Total protegido (USD)',
            totalUsdToday: 'Total USD hoy',
            currencyGain: 'Ganancia de moneda',
            actions: 'Acciones',
            quote: 'Cotizar',
            status: 'Estado',
            add: 'Agregar',
            addFounds: 'Agregar fondos',
            edit: 'Editar',
            receipt: 'Recibo',
        },
        modalForm: {
            textTitle: 'Modificar',
            purchaseDate: 'Fecha de compra',
            provider: 'Proveedor',
            invoice: 'Factura',
            totalAmount: 'Monto total',
            creditPeriod: 'Plazo de crédito',
            dueDate: 'Fecha de vencimiento',
            exchangeRateProtection: 'Protección de tipo de cambio',
            currencyProfit: 'Ganancia de moneda (%)',
            currencyProfitUsd: 'Ganancia de moneda (USD)',
            totalProtectedUsd: 'Total protegido (USD)',
            currencyGain: 'Ganancia de moneda',
            exchangeRateToday: 'Tipo de cambio hoy',
            totalUsdToday: 'Total USD hoy',
            adjust: 'Ajustar',

            cancel: 'Cancelar',
        },
        textTitle: 'Modificar',
        textTitleSepare: 'Cotizar',
        textTitleCreate: 'Crear',
        register: {
            register: 'Registrar',
            sBankName: 'Nombre del banco',
            sAccountNumber: 'Número de cuenta',
            sRoutingNumber: 'Número de ruta',
            clabe: 'Clabe',
            sBankNameMxn: 'Nombre del banco (MXN)',
        },
        registerProvider: {
            email: 'Email',
            name: 'Nombre',
            bank_name: 'Nombre del banco',
            clabe: 'Número de clabe',
        },
        status: {
            TO_PAY: 'Por pagar',
            COMPLETED: 'Pagado',
            SEPARATED: 'Separado',
            WAITING_FOR_DEPOSIT: 'Esperando depósito',
            PENDING_TO_PROCESS_SCHEDULED_PAYMENT: 'Pendiente de procesar pago programado',
        },
        payments: {
            payNow: 'Pagar Ahora',
            payNowDescription: 'Utiliza tu saldo',
            paySeparate: 'Convierte pago a MXN',
            paySeparateDescription: "Convierte tus USD a MXN, luego decide cuándo pagar a tu proveedor",
            payDeferred: 'Pago Diferido',
            payDeferredDescription: 'Asegura un tipo de cambio, después paga por transferencia',
            payForward: 'Pago a Futuro',
            payForwardDescription: 'Protégete de fluctuaciones cambiarias',
        }
    }
};

export default texts;